<template>
	<div class="content-pad narrow">
		<div class="block">
			<h1>Arthur API</h1>
			<p>
				Welcome to the Arthur API documentation.
			</p>
			<p>
				Here you'll find instructions on how to access any data on Arthur and integrate in your own web
				project. It is specifically intended for artists and designers to build their own portfolio websites,
				but you may use it for anything else.
			</p>
			<p>
				If you have any thoughts or questions, let us know at
				<a href="mailto:api@arthur.io">api@arthur.io</a>.
			</p>
		</div>

		<div class="block">
			<h2>1. The Rules</h2>
			<p>
				The Arthur API is in beta. This means that the structure can change any time. When it does, we'll let
				you know but it can be on short notice.
			</p>
			<p>
				The rules are simple. You can use the API as you desire however you can not:
			</p>
			<ul class="bump-b">
				<li>Use it to scrape Arthur.</li>
				<li>
					Use it for projects with unusually high traffic (+10,000 requests/day) unless you let us know about
					it in advance.
				</li>
				<li>
					Use it for anything that can be interpreted as hostile or malicous towards Arthur.
				</li>
			</ul>
			<p>
				We monitor your request traffic and if you are in violation your API key will be revoked.
			</p>
		</div>

		<div class="block">
			<h2>Request Api Key</h2>
			<p>To request an API Key, <a href="#">click here</a>.</p>
		</div>

		<div class="block">
			<h2>Api Endpoints</h2>
			<h3>Artist Info</h3>
			<p>
				Pulls profile data for a specific artist.
			</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/artist/&#60;artist_name_path&#62;?key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs><code class="language-javascript data">{
	name: 'Lara Nasser',
	namePath: 'lara-nasser',
	born: 1991,
	died: null,
	country: 'USA',
	website: 'https://laranasser.com',
	instagram: 'https://instagram.com/laranasserous'
	otherLinks: ['https://meredithrosengallery.com/lara-nasser...']
	wikipedia: 'LaraNasser',
	about: 'Lara Nasser is a Lebanese American sculptor and...'
}</code></pre>
		</div>

		<div class="block">
			<h3>User Info</h3>
			<p>Pulls profile data for a specific user.</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/user/&#60;user_id&#62;?key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs><code class="language-javascript data">{
	name: 'Moenen Erbuer',
	username: 'themoenen',
	about: 'Brb working on Arthur',
	links: ['http://shapish.com', 'http://twitter.com/themoenen'],
	profilePic: [
		'https://arthur.io/.../x1/themoenen.jpg',
		'https://arthur.io/.../x2/themoenen.jpg'
	]
}</code></pre>
		</div>

		<div class="block">
			<h3>Artwork Info</h3>
			<p>Pulls artwork data for a specific artwork.</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/artwork/&#60;artist_name_path&#62;/&#60;artwork_title_path&#62;?key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs>
				<code class="language-javascript data">{
	title: 'Fountain',
	titlePath: 'fountain',
	artistName: 'Marcel Duchamp',
	artistNamePath: 'marcel-duchamp',
	year: '1917', // Or '20th century / 3000 BC / etc.'
	caption: 'Fountain is a readymade sculpture consisting of...',
	dimension: {
		metric: '50cm x 50xm x 40cm',
		imperial: '19.7" x 19.7" x 15.7"'
		height: 500,	// mm
		width: 500,		// mm
		depth: 400		// mm
	}
	labels: ['urinal', 'readymade'],
	medium: 'mixed media',
	materials: ['ceramic']
	substrate: null,
	mediumFreeForm: '',
}</code>
			</pre>
		</div>

		<div class="block">
			<h3>Entity Artworks Feed</h3>
			<p>Pulls artworks data for a specific entity (artist, user, list, room).</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/artworks/entitytId?page=1&amp;pagesize=10&amp;key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs>
				<code class="language-javascript data">{
	page: 1,
	pageSize: 10,
	totalPages: 27,
	total: 267,
	artworks: [{
		// See Artwork Info endpoint
	}, {
		// See Artwork Info endpoint
	}]
}</code>
			</pre>
		</div>

		<div class="block">
			<h3>Room Info</h3>
			<p>Pulls room data for a specific room.</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/room/&#60;id&#62;?key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs>
				<code class="language-javascript data">{
	name: 'Take Me To Your Leader',
	author: {
		name: 'Moenen Erbuer',
		username: 'moenen-erbuer'
	}
}</code>
			</pre>
		</div>

		<div class="block">
			<h3>List Info</h3>
			<p>Pulls list data for a specific list.</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/list/&#60;id&#62;?key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs>
				<code class="language-javascript data">{
	name: 'favorites',
	namePath: 'favorites',
	author: {
		name: 'Moenen Erbuer',
		username: 'moenen-erbuer'
	}
}</code>
			</pre>
		</div>

		<div class="block">
			<h3>User or Artwork Rooms Feed</h3>
			<p>Pulls all the rooms data for a specific user or artwork.</p>
			<pre v-highlightjs>
				<code class="language-plaintext api">https://api.arthur.io/rooms/userOrArtworkId?page=1&amp;pagesize=10&amp;key=&#60;api_key&#62;</code>
			</pre>
			<pre v-highlightjs>
				<code class="language-javascript data">{
	page: 1,
	pageSize: 10,
	totalPages: 27,
	total: 267,
	rooms: [{ room }, { room }]
}</code>
			</pre>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ApiPage',
}
</script>

<style scoped lang="scss">
.block {
	margin-bottom: 0.5rem;
}
</style>
